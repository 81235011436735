import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import "./Workflow.css";
import RunWorkflowButton from './RunWorkflowButton';
import CircularIndeterminate from './CircularIndeterminate'; // Import CircularIndeterminate component
import TextSnippet from '@mui/icons-material/TextSnippet';
import axios from "axios";
import authHeader from "../../src/services/auth-header";
import RefreshWorkflowButton from './RefreshWorkflowButton'; // Import the refresh button

interface Row {
  id: number;
  workflowName: string;
  createdDate: string;
  runDate: string;
  status: string;
  log: string; // Add a log field to store log content
}

const WorkflowTable = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [workflowStatuses, setWorkflowStatuses] = useState<Record<string, string>>({});
  const [workflowLogs, setWorkflowLogs] = useState<Record<string, string>>({});

  useEffect(() => {
    getWorkflows();
  }, []);

  const getWorkflows = () => {
    axios
      .get("/api/getWorkflows", { headers: authHeader() }) // Replace with your API endpoint
      .then((response) => {
        const workflows = response.data.map((workflow: any, index: number) => {
          console.log('Workflow:', workflow); // Log each workflow
          setWorkflowStatuses((prevStatuses) => ({
            ...prevStatuses,
            [workflow.name]: workflow.status || 'New',
          }));
          return {
            id: index + 1,
            workflowId: workflow._id,
            workflowName: workflow.workflowName,
            createdDate: workflow.createdDate,
            runDate: workflow.audit && workflow.audit.startTime ? workflow.audit.startTime : '',
            status: workflow.audit.status,
            log: workflow.audit && workflow.audit.logs && workflow.audit.logs.length ? workflow.audit.logs : '',
          };
        });
        setRows(workflows);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching workflows:", error);
        setLoading(false);
      });
  };

  const executeWorkflow = (workflowName: string) => {
    // Update the status of the workflow to "In Progress"
    setWorkflowStatuses((prevStatuses) => ({
      ...prevStatuses,
      [workflowName]: "In Progress",
    }));

    axios
      .post(`/api/executeWorkflow/${workflowName}`, null, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log(response.data);
        setWorkflowStatuses((prevStatuses) => ({
          ...prevStatuses,
          [workflowName]: response.data.status || "Complete",
        }));
      })
      .catch((error) => {
        console.error("Error executing workflow:", error);
        setWorkflowStatuses((prevStatuses) => ({
          ...prevStatuses,
          [workflowName]: "Failed",
        }));
      });
    console.log(`Executing workflow: ${workflowName}`);
  };

  const downloadLogs = (logContent: string) => {
    logContent = logContent.toString();
    logContent = logContent.replace(/,/g, '\n');
    const blob = new Blob([logContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "workflowLog.txt";
    link.click();
  };

  const refreshWorkflows = () => {
    window.location.reload();
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'workflowName', headerName: 'Workflow Name', flex: 2 },
    { field: 'createdDate', headerName: 'Date Created', flex: 2 },
    { field: 'runDate', headerName: 'Date of Last Run', flex: 2 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      renderCell: (params) => {
        let backgroundColor;
        switch (params.value) {
          case 'Completed':
            backgroundColor = 'rgba(153, 221, 204, 1)'; // Green
            break;
          case 'Failed':
            backgroundColor = 'rgba(255, 0, 0, 0.5)'; // Red
            break;
          case 'Running':
            backgroundColor = 'rgba(153, 221, 235, 0.5)'; // Blue
            break;
          case 'In-Progress':
            backgroundColor = 'rgba(255, 165, 0, 0.5)'; // Orange
            break;
          case 'Waiting...':
            break;
          case 'Open':
            backgroundColor = 'rgba(128, 128, 128, 0.5)'; // Grey
            break;
          default:
            backgroundColor = 'transparent';
        }

        return (
          <div
            style={{
              backgroundColor,
              borderRadius: '20px',
              padding: '0 8px',
              fontWeight: 'bold',
              color: 'black',
              textAlign: 'center',
            }}
          >
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'run',
      headerName: 'Run',
      flex: 1.5,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", height: '100%' }}>
          {params.row.status === 'Running' || params.row.status === 'In-Progress' ? (
            <CircularIndeterminate />
          ) : (
            (params.row.status === 'Completed' || params.row.status === 'In-Progress') ? 
            null : <RunWorkflowButton onClick={() => executeWorkflow(params.row.workflowId)} showReRunText={params.row.status === 'Failed'} />
          )}
        </div>
      ),
    },
    {
      field: 'log',
      headerName: 'Log',
      flex: 1.5,
      renderCell: (params) => {
        const isComplete = params.row.status === 'Completed';
        const iconOpacity = isComplete ? 1 : 0;
        const iconCursor = isComplete ? 'pointer' : 'default';

        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", height: '100%' }}>
            <TextSnippet
              className="log-icon"
              onClick={isComplete ? () => downloadLogs(params.row.log) : undefined}
              style={{ cursor: iconCursor, opacity: iconOpacity }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ height: '60%', width: '85%', margin: 'auto', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
        <RefreshWorkflowButton onClick={refreshWorkflows} />
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 }
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        className="center-pagination custom-data-grid"
      />
    </div>
  );
}

export default WorkflowTable;
