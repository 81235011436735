import { useState, useEffect } from "react";
import { executeCypher } from "./api/ApiService";

import { NeoVis } from 'neovis.js';
import React from "react";
export function Graph() {
	const [loading, setLoading] = useState(false);
	const [config, setConfig] = useState<any>(null);
	const [error, setError] = useState<any>(null);

	const visRef = React.useRef<HTMLInputElement>(null);
	const cypherQueryRef = React.useRef<HTMLInputElement>(null);


	// submit data
	const handleSubmitEvent = (event: any) => {

		event.preventDefault();
		if (cypherQueryRef?.current?.value == '') {
			setError({ message: "Please enter a cypher query" })
			return
		}
		setError(null)

		const payload = {
			query: cypherQueryRef?.current?.value
			//  'MATCH p = (:Character)-[:INTERACTS]->(:Character) RETURN p limit 25',
		}
		//Execute cypher query from input field
		executeCypher({ payload, setLoading, setConfig, setError })
	}

	useEffect(() => {
		/**
		 * Process API response
		 * 1. API response contains the result and neovis config
		 * 2. Append the container Id to the incoming Neovis config
		 * 3. Create a nen object of Neovis with the incoing config
		 * 4. Render the graph
		 * 
		 * */

		if (config) {
			config.containerId = visRef?.current?.id//'viz'

			//Process the relationship labels from config
			Object.keys(config.relationships).forEach((relationship: any) => {

				var nodeRelationship = config.relationships[`${relationship}`]

				config.relationships[`${relationship}`] = {
					[NeoVis.NEOVIS_ADVANCED_CONFIG]: {
						static: {
							label: nodeRelationship.label
						}
					}
				}

			})


			console.log(config)
			const vis = new NeoVis(config);
			// vis.stabilize()
			vis.render();

			// console.log(response.data)
		}


	}, [config, error, loading])


	return (
		<div className="container">
			<div className="col-md-12 m-1">
				<div className="input-group mb-3">

					<input
						type="text"
						ref={cypherQueryRef}
						placeholder="Enter your cypher query here"
						className="form-control"
						id="cypher-query"
						aria-describedby="button-addon2" />
					<button
						style={{ marginTop: -3, height: 40, width: 80 }}
						onClick={handleSubmitEvent}
						type="button" id="btn-execute">Execute</button>
				</div>
				{/* Display error messages whenever they occur from the API  */}
				{error ? <div className="alert alert-danger" role="alert">
					{error.message
					}
				</div> : null}

				{/* Simple loading bar when executing the query  */}
				{loading ? <div id="spinner" className="spinner-border text-warning" role="status">
					<span className="visually-hidden">Loading...</span>
				</div> :
					<div
						id="viz"
						ref={visRef}
						style={{
							width: `100%`,
							height: `500px`,
							border: `1px solid lightgray`,
							font: `22pt arial`

						}}
					/>
				}
			</div>
		</div>
	)
}
