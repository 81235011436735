import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import './AnalysisConfigNodeModal.css'; // Reuse the CSS for modal styling
interface AnalysisConfigNodeModalTypes {
	forwardReads: string;
	reverseReads: string;
	dbsLibrary: string;
	miSeq: string;
}
interface AnalysisConfigNodeModalProps {
	onSave: ({ forwardReads, reverseReads, dbsLibrary, miSeq }: AnalysisConfigNodeModalTypes) => void;
	onClose: () => void;
	open: boolean; // Add open prop
}

interface S3Object {
	Key: string;
	IsFolder: boolean;
}

const AnalysisConfigNodeModal: React.FC<AnalysisConfigNodeModalProps> = ({ onSave, onClose, open }) => {
	const [files, setFiles] = useState<S3Object[]>([]); // List of files from S3
	const [regexForwardRead, setRegexForwardRead] = useState('');
	const [regexReverseRead, setRegexReverseRead] = useState('');
	const [databaseSelection, setDatabaseSelection] = useState('');
	const [outputLocation, setOutputLocation] = useState('');
	// Configure AWS SDK
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
		secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
		region: process.env.REACT_APP_AWS_REGION!,
	});

	const s3 = new AWS.S3();

	const listFiles = async (prefix?: string) => {
		const params = {
			Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
			Prefix: prefix ? prefix : undefined,
		};

		try {
			const data = await s3.listObjectsV2(params).promise();
			const objects = data.Contents?.map((item) => ({ Key: item.Key!, IsFolder: item.Key!.endsWith('/') })) || [];
			setFiles(objects);
		} catch (error) {
			console.error('Error listing files: ', error);
		}
	};

	useEffect(() => {
		if (open) {
			listFiles();
		}
	}, [open]);

	const handleSave = () => {
		onSave({ forwardReads: regexForwardRead, reverseReads: regexReverseRead, dbsLibrary: databaseSelection, miSeq: outputLocation });
		onClose();
	};

	if (!open) return null; // Return null if not open

	return (
		<div className="modal-overlay" onClick={onClose}>
			<div className="modal-content" onClick={(e) => e.stopPropagation()}>
				<div className="form-group">
					<label htmlFor="regexForwardRead" style={styles.label}>Regex Forward Read</label>
					<input
						type="text"
						id="regexForwardRead"
						name="regexForwardRead"
						placeholder="Regex Forward Read"
						value={regexForwardRead}
						required
						onChange={(e) => setRegexForwardRead(e.target.value)}
						style={{ border: '1px solid black', marginLeft: 0 }}
					/>
				</div>
				<div className="form-group">
					<label htmlFor='regexReverseRead' style={styles.label}> Regex Reverse Read</label>
					<input
						type="text"
						id="regexReverseRead"
						name="regexReverseRead"
						placeholder="Regex Reverse Read"
						value={regexReverseRead}
						required
						onChange={(e) => setRegexReverseRead(e.target.value)}
						style={{ border: '1px solid black', marginLeft: 0 }}
					/>
				</div>
				<div style={styles.formGroup}>
					<label htmlFor="database" style={{ ...styles.label, ...{ marginTop: 5 } }}>Database Selection:</label>
					<select
						id="database"
						name="database"
						value={databaseSelection}
						onChange={(e) => setDatabaseSelection(e.target.value)}
						required
						style={styles.select}
					>
						<option value="" disabled>Select a database</option>
						<option value="Silva">Silva</option>
						<option value="HOMD">HOMD</option>
					</select>
				</div>
				<div className="form-group">
					<label htmlFor='outputLocation' style={styles.label}>Output Location</label>
					<input
						type="text"
						id="outputLocation"
						name="outputLocation"
						placeholder="Output Location"
						value={outputLocation}
						required
						onChange={(e) => setOutputLocation(e.target.value)}
						style={{ border: '1px solid black', marginLeft: 0 }}
					/>
				</div>
				<button className="upload-file-btn" onClick={handleSave}>
					Upload
				</button>
			</div>
		</div >
	);
};

const styles = {
	formGroup: {
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '10px',
	},
	label: {
		textAlign: 'left',
		width: '250px', // Adjust this as needed
		marginRight: '10px',
		color: 'black',
	},
	select: {
		padding: '5px',
		flexGrow: 1,
	},
	button: {
		padding: '5px 10px',
		marginTop: '10px',
	}
};

export default AnalysisConfigNodeModal;
