import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Modal({open,handleNewFolderSubmit,handleClose}:any) {

  return (
    <React.Fragment>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            handleNewFolderSubmit(event);
            
           
          },
        }}
      >
        <DialogTitle>New Folder Name</DialogTitle>
        <DialogContent sx={{minHeight:"90px", alignItems:"center",display:"flex"}}>

          <TextField
            autoFocus
            required
            // margin="dense"
            id="name"
            name="email"
            label="New Folder Name"
            type="text"
            focused                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button type="submit">CREATE</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}