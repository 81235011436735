import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ChonkyActions, ChonkyFileActionData, FileActionHandler, FileArray, FileData, FileAction } from 'chonky';
import { FileBrowser, FileContextMenu, FileList, FileNavbar, FileToolbar } from 'chonky';
import AWS from 'aws-sdk';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../../material_ui/Model';


// Set up AWS SDK with your credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY!,
  region: process.env.REACT_APP_AWS_REGION!,
});

const s3 = new AWS.S3();

// Define styles as an object
const useStyles = makeStyles({
  fileBrowserContainer: {
    height: '80vh',
  },
});
const FileBrowserComponent = FileBrowser as React.ComponentType<any>;
function getFileExtension(fileName: string): string | null {
  const lastDotIndex = fileName.lastIndexOf('.');

  // If no dot is found
  if (lastDotIndex === -1) {
      return null;
  }

  // If the dot is the first character and there's no extension, return null
  if (lastDotIndex === 0 && fileName.length === 1) {
      return null;
  }

  return fileName.slice(lastDotIndex + 1);
}

const getFileColor: { [key: string]: string } = {
  'r': 'rgba(255, 0, 0, 1)',
  "php": "rgba(92, 198, 83, 1)",
  "js": "rgba(52, 152, 219, 1)",
  "html": "rgba(241, 196, 15, 1)",
  "css": "rgba(46, 204, 113, 1)",
  "py": "rgba(155, 89, 182, 1)",
  "java": "rgba(231, 76, 60, 1)",
  "c": "rgba(26, 188, 156, 1)",
  "cpp": "rgba(142, 68, 173, 1)",
  "rb": "rgba(44, 62, 80, 1)",
  "swift": "rgba(230, 126, 115, 1)",
  "go": "rgba(52, 152, 219, 1)",
  "rs": "rgba(241, 196, 15, 1)",
  "scala": "rgba(46, 204, 113, 1)",
  "clj": "rgba(155, 89, 182, 1)",
  "kt": "rgba(231, 76, 60, 1)",
  "dart": "rgba(26, 188, 156, 1)",
  "ex": "rgba(142, 68, 173, 1)",
  "cr": "rgba(44, 62, 80, 1)",
  "nim": "rgba(230, 126, 115, 1)",
  "jl": "rgba(52, 152, 219, 1)",
  "elm": "rgba(241, 196, 15, 1)",
  "elixir": "rgba(46, 204, 113, 1)",
  "groovy": "rgba(155, 89, 182, 1)",
  "deno": "rgba(231, 76, 60, 1)",
  "zig": "rgba(26, 188, 156, 1)",
  "v": "rgba(142, 68, 173, 1)",
  "d": "rgba(44, 62, 80, 1)",
  "ada": "rgba(230, 126, 115, 1)",
  "vhdl": "rgba(52, 152, 219, 1)",
  "verilog": "rgba(241, 196, 15, 1)",
  "sol": "rgba(46, 204, 113, 1)",
  "ipynb": "rgba(155, 89, 182, 1)",
  "ts": "rgba(231, 76, 60, 1)",
  "jsx": "rgba(26, 188, 156, 1)",
  "tsx": "rgba(142, 68, 173, 1)",
  "vue": "rgba(44, 62, 80, 1)",
  "svelte": "rgba(230, 126, 115, 1)",
  "json": "rgba(52, 152, 219, 1)",
  "yaml": "rgba(241, 196, 15, 1)",
  "toml": "rgba(46, 204, 113, 1)",
  "xml": "rgba(155, 89, 182, 1)",
  "csv": "rgba(231, 76, 60, 1)",
  "sql": "rgba(26, 188, 156, 1)",
  "md": "rgba(142, 68, 173, 1)",
  "txt": "rgba(44, 62, 80, 1)",
  "pdf": "rgba(230, 126, 115, 1)",
  "docx": "rgba(52, 152, 219, 1)",
  "xlsx": "rgba(241, 196, 15, 1)",
  "pptx": "rgba(46, 204, 113, 1)",
  "zip": "rgba(155, 89, 182, 1)",
  "tar": "rgba(231, 76, 60, 1)",
  "gz": "rgba(26, 188, 156, 1)",
  "dmg": "rgba(142, 68, 173, 1)",
  "exe": "rgba(44, 62, 80, 1)",
  "dll": "rgba(230, 126, 115, 1)",
  "so": "rgba(52, 152, 219, 1)",
  "o": "rgba(241, 196, 15, 1)",
  "a": "rgba(46, 204, 113, 1)",
  "lib": "rgba(155, 89, 182, 1)",
  "dylib": "rgba(231, 76, 60, 1)",
  "pdb": "rgba(26, 188, 156, 1)",
  "pch": "rgba(142, 68, 173, 1)",
  "framework": "rgba(44, 62, 80, 1)",
  "app": "rgba(230, 126, 115, 1)",
  "pkg": "rgba(52, 152, 219, 1)",
  "xpi": "rgba(241, 196, 15, 1)",
  "crx": "rgba(46, 204, 113, 1)",
  "vsix": "rgba(155, 89, 182, 1)",
  "nupkg": "rgba(231, 76, 60, 1)",
  "gem": "rgba(26, 188, 156, 1)",
  "egg": "rgba(142, 68, 173, 1)",
  "whl": "rgba(44, 62, 80, 1)",
  "jar": "rgba(230, 126, 115, 1)",
  "war": "rgba(52, 152, 219, 1)",
  "ear": "rgba(241, 196, 15, 1)",
  "aar": "rgba(46, 204, 113, 1)",
  "apk": "rgba(155, 89, 182, 1)",
  "aab": "rgba(231, 76, 60, 1)",
  "ipa": "rgba(26, 188, 156, 1)",
  "xcframework": "rgba(142, 68, 173, 1)",
  "xarchive": "rgba(44, 62, 80, 1)",
  "gitignore": "rgba(230, 126, 115, 1)",
  "gitattributes": "rgba(52, 152, 219, 1)",
  "gitmodules": "rgba(241, 196, 15, 1)",
  "gitkeep": "rgba(46, 204, 113, 1)",
  "gitconfig": "rgba(155, 89, 182, 1)",
  'rar': 'rgba(123, 121, 182, 1)',
  "folder": "rgb(187, 187, 187)"
}

const MainComponent: React.FC = () => {
  const classes = useStyles();
  const [files, setFiles] = useState<FileArray>([]);
  const [folderChain, setFolderChain] = useState<FileArray>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileData[]>([]);
  const [prefix, setPrefix] = useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [fileext,setFileExt]=useState<any>({})
  const handleClickOpen = () => {
    setOpen(true);
  };
const handleNewFolderSubmit=async (event: React.FormEvent<HTMLFormElement>)=>{
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const formJson = Object.fromEntries((formData as any).entries());
  const folderName = formJson.email;
  if (folderName) {
    try {
      // Construct the prefix for the nested folder
      
      console.log(prefix+folderName);
      // Create the nested folder in the S3 bucket
     const res= await s3.putObject({
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
        Key: `${prefix+folderName}/`,
      }).promise();
      console.log('Folder created successfully:', res);

      // Refresh file list after creating folder
      fetchFilesAndFolders(prefix);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  }
  handleClose();

}
  const handleClose = () => {
    setOpen(false);
  };
  const getfileColor = (key:string) => {
    let getfileext:string=getFileExtension(key)||'txt';

    return getFileColor[getfileext]
  };
  const fetchFilesAndFolders = useCallback(async (prefix: string = '') => {
    try {
      const s3Response:any = await s3.listObjectsV2({
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
        Prefix: prefix==='root'?'':prefix,
        Delimiter: '/',
      }).promise();


      const fetchedFolders: FileArray = s3Response.CommonPrefixes?.map((folder: { Prefix: string; }) => ({
        id: folder.Prefix,
        name: folder.Prefix.split('/').slice(-2)[0],
        isDir: true,
        
      })) || [];

      const fetchedFiles: FileArray = s3Response.Contents?.filter((file: { Key: string; }) => file.Key !== prefix).map((file: { Key: string; }) => ({
        id: file.Key,
        name: file.Key.split('/').pop()!,
        color:getfileColor(file.Key)
        // thumbnailUrl: `https://picsum.photos/400/200`, // Assuming public access
      })) || [];

      setFiles([...fetchedFolders, ...fetchedFiles]);
      
      setPrefix(s3Response.Prefix)
      
    } catch (error) {
     
    }
  }, []);
  useEffect(()=>{
    if(files.length>0)
    setFileExt({})
    files.map((data:any)=>{
      if(data.isDir){

        setFileExt((prevState:any) => ({
          ...prevState,
          ["folder"]: "rgb(187, 187, 187)"
        }));
      }
      else{
      let getfileext:string=getFileExtension(data.id)||'txt';

        setFileExt((prevState:any) => ({
          ...prevState,
          [getfileext]: getFileColor[getfileext]
        }));
      }
    })
  },[files])
  const handleFileAction: FileActionHandler = useCallback(async (data: ChonkyFileActionData) => {
    console.log('File action triggered:', data);
    if (data.id === ChonkyActions.DownloadFiles.id) {
      if (data.state.selectedFiles) {
        for (let index = 0; index < data.state.selectedFiles.length; index++) {
          const file = data.state.selectedFiles[index];
          try {
            // Generate download URL
            const downloadUrl = await s3.getSignedUrlPromise('getObject', {
              Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
              Key: file.id,
            });
            
            // Create an anchor element
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', file.name); // Set download attribute
            link.style.display = 'none'; // Hide the link
            link.target='blank_'
            // Append the anchor element to the document body
            document.body.appendChild(link);
            
            // Trigger the click event to initiate download
            await link.click();
            
            // Remove the anchor element from the document body
            document.body.removeChild(link);
          } catch (error) {
            console.error('Error downloading file:', error);
          }
        }
      }
    }
    
    else if (data.id === ChonkyActions.DeleteFiles.id) {
      if (data.state.selectedFiles) {
        const file = data.state.selectedFiles;
        if (file) {
          const filesToDelete = data.state.selectedFiles.map(file => ({ Key: file.id }));
          if(filesToDelete.length > 0){
          try {
            const res=await s3.deleteObjects({
              Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
              Delete: {
                Objects: filesToDelete,
                Quiet: false,
              },
            }).promise();
            console.log('File deleted successfully:',res);

            // Refresh file list after deletion
            fetchFilesAndFolders(prefix);

          } catch (error) {
            console.error('Error deleting file:', error);
          }
          }
        }
      } else {
        console.log('No files in selectedFiles:', data.state.selectedFiles);
      }
    } else if (data.id === ChonkyActions.UploadFiles.id) {
      document.getElementById('fileInput')?.click();
    
    
    }
    else if(data.id===ChonkyActions.CreateFolder.id) {
      // const folderName = prompt('Enter folder name:');
      handleClickOpen()
  
    }
    else if (data.id === ChonkyActions.OpenFiles.id) {
      const fileToOpen = data.payload.targetFile;
      if (fileToOpen && fileToOpen.isDir) {
        fetchFilesAndFolders(fileToOpen.id);
      }
    }
  }, [folderChain]);
  useEffect(()=>{
    updateFolderChain(prefix);
  },[prefix])
  useEffect(()=>{
console.log(fileext)
  },[fileext])
  const updateFolderChain = (prefix:string) => {
    const parts = prefix.split('/');
    let accumulatedPath = '';
    const folderChain = parts.map((part:string, index:number) => {
      if (index < parts.length - 1) {
        accumulatedPath += part + '/';
      } else {
        accumulatedPath += part;
      }
      return { id: accumulatedPath, name: part, isDir: true };
    });
    setFolderChain([{id:'root',name:"Root",isDir:true },...folderChain]);
  };
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
        Key: prefix+file.name,
        Body: file,
      };

      try {
        const uploadResponse = await s3.upload(params).promise();
        console.log('File uploaded successfully:', uploadResponse);
        event.target.value = '';
        // Refresh file list after upload
        fetchFilesAndFolders(prefix);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleSelectionChange = (fileAction: FileAction, selectedFiles: FileData[]) => {
    console.log('Selection changed:', selectedFiles);
    setSelectedFiles(selectedFiles);
  };

  useEffect(() => {
    fetchFilesAndFolders();
  }, []);
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (!files) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME!,
        Key: prefix + file.name,
        Body: file,
      };

      try {
        const uploadResponse = await s3.upload(params).promise();
        console.log('File uploaded successfully:', uploadResponse);

        // Refresh file list after upload
        fetchFilesAndFolders(prefix);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  
  return (
    <>
    <div className={classes.fileBrowserContainer} onDrop={handleDrop} onDragOver={handleDragOver} >
      <Modal {...{open,handleNewFolderSubmit,handleClose}} />
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={(e:any)=>handleFileChange(e)}
      />
      <DndProvider backend={HTML5Backend}>
        <FileBrowserComponent
          files={files}
          folderChain={folderChain}
          fileActions={[ChonkyActions.UploadFiles, ChonkyActions.DownloadFiles, ChonkyActions.DeleteFiles,ChonkyActions.CreateFolder  ]}
          onFileAction={handleFileAction}
        >
          <FileNavbar  />
          <FileToolbar />
          <FileList/>
          <FileContextMenu />
        </FileBrowserComponent>
      </DndProvider>   
    </div>
    <div className='legend-box' >
       { Object.keys(fileext).map((name)=>{
        return <div style={{display:"flex",gap:"4px",alignItems:"center" }}>
          <div style={{height:"12px",width:"20px",backgroundColor:getFileColor[name]||getFileColor['txt'],boxShadow:'rgba(255, 255, 255, 0.4) 0px 0px 0px 999px inset' }} ></div>
          <span>{name}</span>
        </div>
       })
        }
         </div>
    </>
  );
};

export default MainComponent;