
import axios from "axios";
//uncomment for dev environment
const BASE_URL = "https://devneoviz.graphomics.com/"; //API base endpoint

// uncomment for local environment
// const BASE_URL = "http://localhost:4000/"; //API base endpoint

//  
export async function executeCypher({ payload, setLoading, setConfig, setError }: resultProps) {
	setLoading(true);
	try {
		const response: any = await axios.post(`${BASE_URL}cypher/execute`, payload)

		if (response.data.status == 200) {


			var config = response.data.config
			/**
			 * The Neovis config requires a data function when not provided with a neo4j connection
			 * Append  dataFunction into the config which returns a promise of the query result
			 * 
			 */

			config.dataFunction = async () => { return response.data.data }

			// console.log( config)
			setConfig(config)
			return
		}

		setError(response.data)



	} catch (error) {
		setError(error)
	} finally {
		setLoading(false)
	}
}
//types
export interface queryProps {
	query: string | any,
}

export interface resultProps {
	payload: queryProps,
	setLoading: (value: boolean) => void,
	setConfig: (value: any) => void,
	setError: (value: any) => void,
}