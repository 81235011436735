import { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
// import { Routes, Route, Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/App1.css";

import PrivateRoute from "./PrivateRoute";
import AuthService from "./services/auth.service";
import IUser from "./types/user.type";
import logo from "./logo2.png";
import Login from "./components/login/login.component";
import Register from "./components/misc/register.component";
import Home from "./components/home/home.component";
import Profile from "./components/profile/profile.component";
import BoardUser from "./components/board/board-user.component";
import BoardModerator from "./components/board/board-moderator.component";
import BoardAdmin from "./components/board/board-admin.component";
import AddTutorial from "./components/tutorial/AddTutorial";
import Tutorial from "./components/tutorial/Tutorial";
import TutorialsList from "./components/tutorial/TutorialsList";
import Execute from "./components/workflow/Execute";
import CsvUpload from "./components/csvUpload/CsvUpload";
import CsvUpload12 from "./components/csvUpload/CsvUpload12";
import EditRelation from "./components/relationship/editRelation";
import DelLabel from "./components/label/DelLabel";
import EventBus from "./common/EventBus";
import Visualization from "./components/visualisation/Visualization";
import DataUpload from "./components/fileUpload/DataUpload";
import Relationships from "./components/relationship/Relationships";
import WorkflowBuilderv1 from "./components/workflow/WorkflowBuilder copy";
import WorkflowBuilderv2 from "./components/workflow/WorkflowBuilder";
import WorkflowList from "./components/workflow/WorkflowList";
import FolderTree from "./components/fileUpload/FolderTree";
import MainComponent from "./components/misc/MainComponent";
import NotebookViewer from "./components/notebook/NotebookViewer";
import NotebookExecute from "./components/notebook/NotebookExecute";
import NotebookEditor from "./components/notebook/NotebookEditor";
import DocLLM from "./components/DocLLM/DocLLM";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthProvider } from '../src/context/AuthContext';
import { Graph } from "./components/graph/Graph";

type Props = {};

type State = {
  showModeratorBoard: boolean;
  showAdminBoard: boolean;
  currentUser: IUser | undefined;
  lastClicked: string; //add this
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      lastClicked: '', //----add this
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showModeratorBoard: user.roles.includes("ROLE_MODERATOR"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", this.logOut);
  }

  componentWillUnmount() {
    EventBus.remove("logout", this.logOut);
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }

  handleMenuClick = (menuItem: string) => {
    if (this.state.lastClicked === menuItem) {
      window.location.reload();
    } else {
      this.setState({ lastClicked: menuItem } as Pick<State, keyof State>);
    }
  };

  render() {
    const { currentUser, showModeratorBoard, showAdminBoard } = this.state;

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="app-container">
          <nav className="navbar navbar-expand navbar-dark bg-dark">
            <Link to={"/home"}>
              {" "}
              <img src={logo} alt="Graphomics Logo" className="logo2" />
            </Link>

            {/* {currentUser ? ( */}
            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Workflow
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link to={"/workflowv1"} onClick={() => this.handleMenuClick('/workflowv1')} className="dropdown-item">
                      Create Workflow
                    </Link>
                    {/*<Link to={"/workflowv2"} className="dropdown-item">
                      Create Workflow v2
                    </Link>*/}
                    <Link to={"/home"} onClick={() => this.handleMenuClick('/home')} className="dropdown-item">
                      View Workflows
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link to={"/setup"} onClick={() => { this.handleMenuClick('/setup') }} className="nav-link">
                    Data Management
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  <Link to={"http://dev.graphomics.com:8888/"} onClick={() => this.handleMenuClick('/')} target="_blank" className="nav-link">
                    Jupyter Notebook</Link>
                </li>
                {/*<li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Notebook
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link to={"/jynb"} className="dropdown-item">
                      Notebook Viewer
                    </Link>
                    <Link to={"/jynbexe"} className="dropdown-item">
                      Notebook Execute
                    </Link>
                    <Link to={"/jynbedit"} className="dropdown-item">
                      Notebook Editor
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Graph Database
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link to={"/visual"} className="dropdown-item">
                      View Graph
                    </Link>
                    <Link to={"/editRel"} className="dropdown-item">
                      View Graph Relationships
                    </Link>
                    <Link to={"/delLabel"} className="dropdown-item">
                      Delete Graph Labels
                    </Link>
                  </div>
                </li>*/}

                {/* <li className="nav-item">
                  <Link to={"/visual"} className="nav-link">
                  Visualization
                  </Link>
                </li> */}

                <li className="nav-item">
                  {" "}
                  <Link to={"/docllm"} className="nav-link" onClick={() => this.handleMenuClick('/docllm')}>
                    DocLLM
                  </Link>{" "}
                </li>
                <li className="nav-item">
                  {" "}
                  <Link to={"/graph"} onClick={() => { this.handleMenuClick('/graph') }} className="nav-link">
                    Neo4J Graph
                  </Link>{" "}
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    OneClickFunctional
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link to={"/relationships"} className="dropdown-item">
                      Create Relationship
                    </Link>
                    <Link to={"/csvupload"} className="dropdown-item">
                      CSV Upload
                    </Link>
                    <Link to={"/loadData"} className="dropdown-item">
                      Load Data
                    </Link>
                    <Link to={"/execute"} className="dropdown-item">
                      Execute
                    </Link>
                  </div>
                </li> */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {currentUser.username}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link to={"/profile"} className="dropdown-item">
                      Profile
                    </Link>
                    <Link to={"/login"} className="dropdown-item" onClick={this.logOut}>
                      Logout
                    </Link>
                  </div>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link">
                    Login
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Sign Up
                  </Link>
                </li> */}
              </div>
            )}
          </nav>

          <div className="container-fluid">
            <main className="col-12">
              {/* <RemountOnNavigate> */}
              <Routes>
                {/* Generic Application Related */}           {/* Incomlete, Needs work */}
                <Route path="/" element={<Login />} />
                <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />           {/* Incomlete, Needs work */}
                <Route path="/profile" element={<Profile />} />
                <Route path="/user" element={<BoardUser />} />              {/* Incomlete, Needs work */}
                <Route path="/mod" element={<BoardModerator />} />          {/* Incomlete, Needs work */}
                <Route path="/admin" element={<BoardAdmin />} />            {/* Incomlete, Needs work */}

                {/* Tutorial Related */}
                <Route path="/tutorials" element={<TutorialsList />} />     {/* Can be deleted */}
                <Route path="/add" element={<AddTutorial />} />             {/* Can be deleted */}
                <Route path="/tutorials/:id" element={<Tutorial />} />      {/* Can be deleted */}

                {/* Workflow related */}
                <Route path="/workflowv1" element={<PrivateRoute><WorkflowBuilderv1 /></PrivateRoute>} />
                <Route path="/workflowv2" element={<WorkflowBuilderv2 />} />
                <Route path="/workflowlist" element={<WorkflowList />} />

                {/* Data management related */}
                <Route path="/setup" element={<PrivateRoute><MainComponent /></PrivateRoute>} />
                <Route path="/graph" element={<PrivateRoute><Graph /></PrivateRoute>} />

                {/* Notebook related */}
                <Route path="/jynb" element={<NotebookViewer />} />
                <Route path="/jynbexe" element={<NotebookExecute />} />
                <Route path="/jynbedit" element={<NotebookEditor />} />

                {/* Graph DB related */}
                <Route path="/visual" element={<PrivateRoute><Visualization /></PrivateRoute>} />
                <Route path="/editRel" element={<EditRelation />} />
                <Route path="/delLabel" element={<DelLabel />} />
                {/*Doc LLM Related */}
                <Route path="/docllm" element={<PrivateRoute><DocLLM /></PrivateRoute>} />
                {/* Yet to figure out */}
                <Route path="/relationships" element={<Relationships />} /> {/* ? */}
                <Route path="/csvupload" element={<CsvUpload12 />} />       {/* ? */}
                <Route path="/loadData" element={<DataUpload />} />         {/* ? */}
                <Route path="/execute" element={<Execute />} />             {/* ? */}
              </Routes>
              {/* <ReloadOnNavigate /> */}

            </main>
          </div>
        </div>
      </DndProvider>
    );
  }
}

export default App;
